<template>
  <svg viewBox="0 0 119.8 144.8" class="w-14 h-14">
    <path class="orange-3 fill-none stroke-orange-loader" d="M99.5,21.9c-53.6,0-73.3,43.7-73.3,70.8" stroke-width="11" stroke-dasharray="250" stroke-dashoffset="-250"/>
    <path class="orange-2 fill-none stroke-orange-loader" d="M99.5,41.8c-39.4,0-53.4,31.3-53.4,50.9" stroke-width="11" stroke-dasharray="250" stroke-dashoffset="-250"/>
    <path class="orange-1 fill-none stroke-orange-loader" d="M99.5,64.1c-16.5,0-27,12.3-28.9,18.8-2.6,9.2-1.8,18.7-1.7,45.3" stroke-width="14" stroke-dasharray="250" stroke-dashoffset="-250"/>

    <path class="blue-3 fill-none stroke-blue" d="M99.5,21.9c-53.6,0-73.3,43.7-73.3,70.8" stroke-width="11" stroke-dasharray="250" stroke-dashoffset="-250"/>
    <path class="blue-2 fill-none stroke-blue" d="M99.5,41.8c-39.4,0-53.4,31.3-53.4,50.9" stroke-width="11" stroke-dasharray="250" stroke-dashoffset="-250"/>
    <path class="blue-1 fill-none stroke-blue" d="M99.5,64.1c-16.5,0-27,12.3-28.9,18.8-2.6,9.2-1.8,18.7-1.7,45.3" stroke-width="14" stroke-dasharray="250" stroke-dashoffset="-250"/>
  </svg>
</template>

<script>
import gsap from 'gsap';

export default {
  name: 'RealyncLoader',
  mounted() {
    this.$nextTick(() => {
      this.tween();
    });
  },
  methods: {
    tween() {
      const timeline = gsap.timeline({
        ease: 'linear',
        repeat: -1,
        repeatDelay: 0.3,
        yoyo: true
      });

      timeline
        .fromTo('.orange-1', { strokeDashoffset: -250 }, { strokeDashoffset: 0, duration: 0.3 })
        .fromTo('.orange-2', { strokeDashoffset: -250 }, { strokeDashoffset: 0, duration: 0.225 }, 0.15)
        .fromTo('.orange-3', { strokeDashoffset: -250 }, { strokeDashoffset: 0, duration: 0.375 }, 0.375)
        .fromTo('.blue-1', { strokeDashoffset: -250 }, { strokeDashoffset: 0, duration: 0.45 }, 0.15)
        .fromTo('.blue-2', { strokeDashoffset: -250 }, { strokeDashoffset: 0, duration: 0.3 }, 0.525)
        .fromTo('.blue-3', { strokeDashoffset: -250 }, { strokeDashoffset: 0, duration: 0.3 }, 0.675);
    }
  }
};
</script>
